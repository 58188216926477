@import "~@inpi-dm/components/src/styles/global.scss";
@import '~react-toastify/dist/ReactToastify.css';

@import "styles/variables";
@import "styles/global";
@import "styles/fonts";
@import "common/notFound";
@import "component/login/login";
@import "component/stepper/stepContainer";
@import "component/header/header";
@import "component/help/help";
@import "component/footer/footer";
@import "component/home/dashboard/dashboard";
@import "component/requests/request";
@import "component/deposit/form/overview/overview";
@import "component/deposit/form/models/reproduction/reproduction";
@import "component/deposit/form/intervenant/intervenantForm";
@import "component/intervenant/codepositor";
@import "component/payment/paymentForm";
@import "component/payment/payment";
@import "component/deposit/form/overview/reproductions/listReproductions";
@import "component/deposit/form/overview/notifications/OverviewNotificationsInfo";
@import "component/deposit/form/models/modelForm/ModelForm";
@import "component/prorogation/form/overview/overview";
