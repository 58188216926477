.select-holder {
  position: relative;

  .select-holder-items {
    position: absolute;
    border: 1px solid $gray-middle;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 1rem;

    div {
      background-color: $gray-light;
      border-bottom: 1px solid $gray-middle;
      color: $gray-dark;
      padding: 3px 10px;

      &.item {
        color: $blue-dark;
        cursor: pointer;

        &.active {
          background-color: $gray-middle;
        }
      }
    }
  }
}