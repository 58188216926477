.container-step {
  .step-list {
    .title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 21px;
      margin-bottom: 2.5rem;
    }

    .list {
      .list-action {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icone {
          display: none;
        }

        &.level-0 {
          border-top: 1px solid $gray-middle;
          color: $blue-dark;
          font-size: 1rem;
          font-weight: 700;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }

        &.level-1 {
          padding-left: 4rem;
          padding-bottom: 0.5rem;
        }

        &.level-2 {
          padding-left: 5rem;
          padding-bottom: 0.5rem;
          font-size: 15px;
        }

        &.active {
          color: $primary;

          .icone {
            display: inline;
          }
        }
      }
    }
  }

  .step-view {
    .card-body {
      display: flex;
      flex-direction: column;

      .view {
        flex-grow: 1;
      }

      .step-buttons {
        align-self: flex-end;
        width: 100%;
      }
    }
  }
}