.validation-error {
  display: none;
  position: absolute;
  color: $danger;
  font-size: 0.8em;
}

.validation-warning {
  display: none;
  position: absolute;
  color: $warning;
  font-size: 0.8em;
}

.is-validated {
  .validation-error {
    display: block;
  }
  .validation-warning {
    display: block;
  }
}

.relative-position {
  position: relative;
}
