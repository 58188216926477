@mixin vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#mainHeader {
  background-color: $white;

  .nav {
    height: 100%;

    .nav-item {
      @include vertical-center;
      border-bottom: 4px solid transparent;

      &.active {
        font-weight: bold;
        border-color: $primary;
      }

      .logo {
        margin: 5px;
        img {
          height: 55px;
        }
      }

      .nav-link {
        @include vertical-center;
        color: $black;
        height: 100%;
        padding-top: 4px;
        padding-bottom: 0;

        &:hover {
          color: $primary;
        }

        img {
          width: auto;
          height: 1.5rem;
        }
      }
    }
  }
}

.header-msg {
  background-color: $gray-light;

  p {
    margin-bottom: 0;
  }
}