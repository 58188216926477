.page-not-found {
    max-width: 500px;
    margin: 50px auto 0;
    padding: 40px;
    border: 1px solid $gray-500;
    text-align: center;

    .title {
        font-size: 50px;
        letter-spacing: 10px;
        margin-bottom: 10px;
    }

    .desc {
        font-size: 20px;
        margin-bottom: 20px;
    }
}

.go-back-btn {
    min-width: 160px;
}