.dropzone-section {
  width: 100%;

  .dropzone-area {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    color: $blue-dark;
    cursor: pointer;
    outline: none;
    padding: 1.5rem;

    p {
      margin: 0;
    }

    svg {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  .validation-error {
    position: relative;
  }
}
