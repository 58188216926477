#mainFooter {
  width: 100%;
  height: $main-footer-height;
  background-color: $primary;
  color: $white;
  text-align: center;

  .nav {
    .nav-item {
      @include vertical-center;

      .nav-link {
        color: $white;
        padding: 0.5rem 2rem;
      }

      &.separated {
         + .separated {
           border-left: 1px solid rgba(255, 255, 255, .3);
         }
      }
    }
  }
  .version {
    font-size: 10px;
    text-align: left;
  }
}
