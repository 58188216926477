.card {
  height: 100%;
  border: $border-width dotted transparent;

  &.no-border{
    border: 0;
  }

  .card-header{
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px 5px 0 0;
  }

  .card-body {
    padding: 2rem;

    .title {
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }

    .badge {
      font-size: 1.25rem;
    }
  }

  &.cursor-pointer {
    border-color: transparent !important;

    &:hover {
      @each $name, $value in $theme-colors {
        &.theme-#{$name} {
          border-color: $value !important;

          @if map-has-key($theme-hover-colors, $name) {
            background-color: map-get($theme-hover-colors, $name);
          } @else {
            background-color: lighten($value, 50%);
          }
        }
      }
    }

    &.active {
      @each $name, $value in $theme-colors {
        &.theme-#{$name} {
          border-color: $value !important;
        }
      }
    }
  }
}
