$primary: #00798C;
$secondary: #FF5A60;
$blue-dark: #171f2a;
$gray-dark: #656665;
$gray-middle: #DFDFE0;
$gray-light: #F3F4F6;
$off-white: #FDFDFD;
$inpi-dm: #BCCF00;
$orange: #eb8c2f;
$green: #109357;
$brown: #B86308;


$theme-colors: (
  "inpi-dm": $inpi-dm,
  "gris": $gray-dark
);

$gray-100: $gray-light;
$gray-400: $gray-middle;
$gray-700: $gray-dark;
$gray-900: $blue-dark;

$border-radius: 5px;
$border-width: 3px;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;

$input-bg: $gray-light;
$input-border-width: 2px;
$input-color: $blue-dark;
$input-focus-border-color: $gray-middle;
$input-focus-box-shadow: 0 0 0 .2rem $gray-light;

$custom-control-indicator-border-width: 1px;
$custom-checkbox-indicator-border-radius: 2px;
$custom-select-focus-box-shadow: 0 0 0 .2rem $gray-light;

$highlight-badge-bg-color: #F18700;

$label-margin-bottom: .25rem;

$theme-hover-colors: (
        "primary": lighten($primary, 70%),
        "secondary": lighten($secondary, 31%),
        "inpi-dm": lighten($inpi-dm, 55%),
        "gris": lighten($gray-dark, 55%),
);

:export { primary: $primary; secondary: $gray-middle; RgbPrimary: rgb(0, 121, 140); RgbSecondary: rgb(255, 90, 96); RgbBlack: rgb(0, 0, 0); RgbInpi: rgb(188, 207, 0); }