.payment-div {
  width: 50%;
  height: 30vh;
  min-height: 370px;
  min-width: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waiting-msg {
  font-size: large;
}