.reproduction {
  border: 1px solid $gray-light;

  .btn-link {
    display: flex;
    min-width: 0;
    color: $blue-dark;
  }

  .img-thumbnail {
    width: 75px;
    height: auto;
    padding: 0;
    border:0;
    border-radius: 0;
  }
}
