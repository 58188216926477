html, body, #root {
  height: 100%;
  font-family: "Inpi-Regular", "Helvetica Neue", Arial, sans-serif;
}

.global {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  min-height: 100%;
  background-color: $gray-light;
}

.main {
  margin-bottom: $main-footer-height;
}

.mt-07 {
  margin-top: 0.7rem;
}

.mb-025 {
  margin-bottom: 0.25rem;
}

.mt-025 {
  margin-top: 0.25rem;
}

.Toastify__toast--error {
  background-color: $red;
}

.Toastify__toast-container--top-right {
  width: auto;
}

.Toastify__toast-body {
  padding: 0.5rem;
}

.min-width-auto {
  min-width: auto;
}

.bg-ajourned {
  background-color: $deposit-ajourned;
}

.bg-simplified {
  background-color: $deposit-simplified;
}

.bg-classique {
  background-color: $deposit-classique;
}