form {
  text-align: left;
}

.form-header {
  border-bottom: $gray-middle 1px solid;
  padding-bottom: 0.5rem;

  .options {
    display: flex;
    justify-content: flex-end;
  }
}

.header-field {
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.form-group {
  label {
    color: $gray-dark;

    &.form-check-label {
      color: $blue-dark;
    }
  }
}

.form-control {
  background-color: $off-white;

  &.placeholder {
    color: $input-placeholder-color;

    option {
      color: $input-color;
    }
  }
}