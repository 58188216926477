.highlight-badge {
  color: white;
  background-color: $highlight-badge-bg-color;
}
.badge-pill {
  font-size: inherit;
  line-height: 7px;
  border: 1px solid $gray-middle;
  margin-right: 1rem;
  padding: 1em;

  &.top-right {
    position: absolute;
    right: 0;
    top: 0.5rem;
  }

  &.svg {
    font-size: 1.1rem !important;
  }
}