.form-intervenant {
  .form-header {
    .options {
      .separator {
        border: solid 1px $gray-middle;
        margin: 0 1rem;

        &:last-child {
          display: none;
        }
        &:first-child {
          display: none;
        }
      }
    }
  }
}