@mixin breadcrumb-before {
  padding-left: 0.7rem;

  &:before {
    display: inline-block;
    padding-right: 0.2rem;
    color: $gray-500;
    content: "\003009";
  }
}

@mixin breadcrumb-list {
  + a {
    @include breadcrumb-before;
  }

  + span {
    @include breadcrumb-before;
  }
}

.breadcrumb {
  font-size: 14px;
  font-weight: bold;
  background-color: transparent;
  margin: 0;

  a {
    @include breadcrumb-list;
    color: $primary;
  }

  span {
    @include breadcrumb-list;
  }

}
