.img-preview {
  height: 75px;
  width: auto;
  margin-right: 0.5rem;
}

.img-bopi {
  max-height: 8cm;
  max-width: 8cm;
  border: 1px solid $gray-middle;
}

.img-bordered {
  border: 1px solid $gray-middle;
}