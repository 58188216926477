@mixin vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

//Adapte les couleurs du dropdown
@mixin dropdown-colors($class-name, $color-bg, $color-text, $color-hover) {
  &.#{$class-name} {
    .btn-link, .btn-link:hover, .btn-link:focus {
      color: $color-text;
    }

    .header-dropdown-menu {
      background-color: $color-bg;
    }

    .header-dropdown-item {
      color: $color-text;
      &:hover, &:focus {
        background-color: $color-hover;
      }
    }

    .disabled {
      color: darken($color-text, 30%)
    }
  }
}

.nav-dropdown {
  @include vertical-center;

  .btn-link, .btn-link:hover, .btn-link:focus {
    color: white;
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  .header-dropdown-menu {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: $blue-dark;
    z-index: 3;
    white-space: nowrap;
    overflow-y: auto;
    .header-dropdown-item {
      padding: 0.4rem 0.6rem;
      color: white;
      outline: none;
      box-shadow: none;
      text-decoration: none;
      &:hover, &:focus {
        background-color: lighten($blue-dark, 20%);
      }
    }
  }

  @include dropdown-colors('nav-dark', $blue-dark, $white, lighten($blue-dark, 20%));
  @include dropdown-colors('nav-white', $white, $black, $gray-light);
  @include dropdown-colors('nav-grey', $gray-dark, $white, lighten($gray-dark, 20%));
  @include dropdown-colors('nav-primary', $primary, $white, lighten($primary, 10%));
}