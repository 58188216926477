.login-page {
  padding-top: 90px;
  text-align: center;
}

.login-page h1 {
  margin-bottom: 50px;
}

.login-page header {
  font-weight: 700;
  font-size: 30px;
  padding: 3rem 0;
}

.login-page .form-group {
  padding-bottom: 20px;
}

.login-page a {
  font-size: .88em;
}
