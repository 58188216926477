.notif-ellipsis {
  font-weight: bold;
  color: $secondary;

  &:hover {
    color: $secondary;
  }
}

.notif-bloc-content {
  max-height: 350px;
  overflow-y: hidden;
}