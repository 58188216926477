.dashboard {
  .item {
    .badge {
      font-size: inherit;
      padding: 0.5rem 1rem;
      color: white;
      border: 0;
      line-height: 18px;

      &.bg-light {
        color: $blue-dark;
      }
    }
  }
}

.home {
  .header-dropdown-menu {
    white-space: normal;
  }

  .procedure-btn {
    min-width: auto;
  }
}