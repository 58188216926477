table.table {
  table-layout: fixed;

  thead th {
    vertical-align: middle;
  }

  .table-checkbox {
    width: 3rem;

    .form-group {
      margin-bottom: 0;
    }
  }

  .status {
    font-weight: 500;
    color: white;
  }

  .fit-parent {
    max-width: 100%;
  }

  .procedure-type{
    font-weight: 500;
    color: white;

    .DEPOSIT_TYPE span{
      background-color: $secondary;
    }

    .PROROGATION_TYPE span{
      background-color: $primary;
    }

    .INSCRIPTION_TYPE span{
      background-color: $gray-dark;
    }

    .APPEAL_TYPE span{
      background-color: $brown;
    }

    .OFFICIAL_DOCUMENTS_TYPE span{
      background-color: $green;

    }
  }
}

.little-icon{
  width: 50px;
}
