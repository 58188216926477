.request {
  .item {
    .badge {
      font-size: inherit;
      padding: 0.5rem 1rem;
      color: white;
      border: 0;
      line-height: 18px;
      margin-right: 0;

      &.bg-light {
        color: $blue-dark;
      }
    }
  }

  .card-body{
    padding: 0.5rem;
  }

  .active{
    .card{
      border: 1px solid $secondary !important;
      background-color: map-get($theme-hover-colors, "secondary");
    }
  }
}

.action-button-request {
  width: 5%;
}

.expired-notifications {
  background-color: lightgrey !important;
}

.vertical-banner {
  width: 3px;
}