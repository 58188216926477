.rdw-colorpicker-modal-options {
  overflow: auto;
}

.rdw-colorpicker-modal {
  width: 200px;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.modal-full-width {
  max-width: 100%;
  height: 100%;
}