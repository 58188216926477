.reproduction-list {
  .select-all {
    margin-left: 0.75rem;
  }

  table.table {
    .tr-model {
      background-color: $gray-middle;

      &:hover {
        background-color: $gray-middle;
      }

      td {
        border-left: 1px solid $gray-middle;
        border-right: 1px solid $gray-middle;
      }
    }

    .reproduction {
      td {
        border-top-width: 1px;
      }
    }

    .reproduction-number {
      width: 7rem;
      text-align: left;
    }

    .reproduction-thumbnail {
      width: 12rem;
      text-align: left;
    }
  }
}