.inline-text-field {
  .input-group {
    flex-wrap: nowrap;

    .form-control {
      min-width: 100%;
      border-right: 0;

      &:focus {
        box-shadow: none;
      }
    }
    .no-min-width {
      min-width: auto !important;
    }
  }

  .input-group-prepend {
    background-color: $white;
    border: $input-border-width solid $gray-middle;
    border-bottom-left-radius: $input-border-radius;
    border-top-left-radius: $input-border-radius;
    border-right: 0;
  }

  .input-group-append {
    background-color: $off-white;
    border: $input-border-width solid $gray-middle;
    border-bottom-right-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
    border-left: 0;

    .btn {
      min-width: 2rem;
      padding: 0 0.3rem;
      margin: 2px;
      border-bottom-left-radius: $btn-border-radius;
      border-top-left-radius: $btn-border-radius;
    }
  }
}
